import React from 'react';

function Contact() {
  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <address>
        <p>Email: vausenterprises9669@gmail.com</p>
        <p>Address: Kohat Enclave, Pitampura, Delhi - 110034</p>
      </address>
    </section>
  );
}

export default Contact;