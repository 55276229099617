import React from "react";
import {Routes, Route} from "react-router-dom";
import HomePage from "../views/home-page";
import Contact from "../views/contact";

function AppRouter() {
    return(
        <Routes>
            <Route path={"/"} element={<HomePage />} />
            <Route path={"/contact"} element={<Contact />} />
        </Routes>
    );
}

export default AppRouter;